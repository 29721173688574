<div class="col-12 d-flex justify-content-between">
    <h1 mat-dialog-title class="dialog-title pl-1 text-white">
        Info
    </h1>
    <button mat-button mat-dialog-close class="dialog-close-button pr-2 text-white" style="border: none"
        (click)="close()">X</button>
</div>
<div mat-dialog-content>
    <p class="info-message text-center">
        {{ data?.message }}
    </p>
</div>
<div mat-dialog-actions align="center" class="d-flex gap-2 py-3 px-4">
    @if(data?.buttons?.length){
    @for (button of data.buttons; track $index) {
    <button mat-button mat-dialog-close class="py-2 px-5 dialog-close-button"
        (click)="close($index)">{{button}}</button>
    }
    }@else {
    <button mat-button mat-dialog-close class="py-2 px-5 dialog-close-button">Ok</button>
    }
</div>