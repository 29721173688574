import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment } from 'csoft-library/modelli';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TranslationsService {
  isLoaded = false;
  TRANSL: any = null;
  VALIDATION: any = null;

  constructor(
    private http: HttpClient,
    @Inject('env') private environment: Environment
  ) {}

  async Init() {
    await this.GetTranslations();
    await this.GetValidations();
    this.isLoaded = true;
  }

  async GetTranslations() {
    const url = `assets/Translations/${this.environment.api_lang.toUpperCase()}/translations.json`;

    this.TRANSL = await firstValueFrom(this.http.get<string>(url));
  }

  async GetValidations() {
    const url = `assets/Validations/${this.environment.api_lang.toUpperCase()}/validations.json`;

    this.VALIDATION = await firstValueFrom(this.http.get<string>(url));
  }
}
