import { Component, Injectable, NgZone } from '@angular/core';
import {
  DialogPosition,
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslationsService } from './translations.service';
import { ConfirmDialog } from 'csoft-library/modelli';
import {
  ConfirmDialogueComponent,
  ErrorDialogComponent,
  InfoDialogComponent,
  ProgressDialogComponent,
  ModalComponent,
} from 'csoft-library/dialogs';
import { UserService } from './user.service';
import { Observable, of, switchMap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorDialogService {
  private opened = false;
  dialogRef: MatDialogRef<any> = null;

  constructor(
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private zone: NgZone,
    private TransSvc: TranslationsService,
    private UserSvc: UserService
  ) {}

  showClientError(message?: string) {
    // The snackbar or dialog won't run outside the Angular's zone.
    // Wrapping it in the run method fixes this issue.
    if (message) console.error(message);
    if (!this.opened) {
      this.zone.run(() => {
        this.dialogRef = this.dialog.open(ErrorDialogComponent, {
          data: {
            message: message || this.TransSvc.TRANSL.error,
            status: '',
            TRANSL: this.TransSvc.TRANSL,
            User: this.UserSvc.User,
          },
          id: 'ClientErrorDialog',
          disableClose: true,
          hasBackdrop: true,
          autoFocus: true,
          backdropClass: 'dialog-backdrop',
        });

        this.dialogRef.afterClosed().subscribe(() => {
          this.opened = false;
        });
      });
    }
  }

  openServerErrorDialog(message?: string, status?: number) {
    if (message) console.error(message);
    if (!this.opened) {
      this.opened = true;
      this.zone.run(() => {
        this.dialogRef = this.dialog.open(ErrorDialogComponent, {
          data: {
            message: this.TransSvc.TRANSL.warning,
            status: status,
            TRANSL: this.TransSvc.TRANSL,
            User: this.UserSvc.User,
          },
          id: 'ServerErrorDialog',
          width: '40%',
          disableClose: true,
          hasBackdrop: true,
          backdropClass: 'dialog-backdrop',
        });

        this.dialogRef.afterClosed().subscribe(() => {
          this.opened = false;
        });
      });
    }
  }

  showNonErrorSnackBar(message: string, duration = 6000) {
    this.snackbar.open(message, 'X', {
      panelClass: ['non-error-snack'],
      duration,
    });
  }

  confirmDialog(data: ConfirmDialog): Observable<boolean> {
    this.dialogRef = this.dialog.open(ConfirmDialogueComponent, {
      data: data,
      id: 'ConfirmDialog',
      width: '40%',
      disableClose: true,
      hasBackdrop: true,
      backdropClass: 'dialog-backdrop',
    });
    return this.dialogRef.afterClosed();
  }

  ShowInfo(message?: string, buttons?: string[]): void | Observable<number> {
    if (message) console.log(message);
    this.dialogRef = this.dialog.open(InfoDialogComponent, {
      data: {
        message: message || this.TransSvc.TRANSL.warning,
        buttons: buttons || null,
      },
      id: 'InfoDialog',
      disableClose: true,
      hasBackdrop: true,
      autoFocus: true,
      backdropClass: 'dialog-backdrop',
    });
    //SE HO DATO UNO O PIU OPZIONI CLICCABILI ALLORA ASPETTO L'INPUT UTENTE
    if (buttons?.length > 0) {
      return this.dialogRef.afterClosed().pipe(
        switchMap((value: any) => {
          if (value === undefined || value === null) {
            return throwError(() => null);
          } else {
            return of(value);
          }
        })
      );
    }
  }

  ShowProgress(
    message: string,
    filename?: string
  ): MatDialogRef<ProgressDialogComponent> {
    this.dialogRef = this.dialog.open(ProgressDialogComponent, {
      data: {
        message: message,
        filename: filename,
      },
      id: 'ProgressDialog',
      disableClose: true,
      hasBackdrop: true,
      backdropClass: 'dialog-backdrop',
    });
    return this.dialogRef;
  }

  //Open Modal Factory non in uso, momentaneamente lascio qui per eventuali cambi in futuro 13/03/2024 Fabian S.
  openModal(message?: string, modal?: any) {
    this.dialogRef = this.dialog.open(ModalComponent, {
      data: modal || message,
      hasBackdrop: true,
      autoFocus: true,
      width: '70%',
    });
  }
}
