<h1 mat-dialog-title class="d-flex align-items-end dialog-title" style="color: white;">
  Error
</h1>
<div mat-dialog-content style="color: white;">
  <p *ngIf="data?.status">
    Status Code: {{ data.status }}
  </p>
  <!-- <p>Something went wrong!</p> -->
  <p class="error-message text-center">
    {{ data?.message }}
  </p>
</div>
<div mat-dialog-actions align="center" class="px-4">
  <!-- <button mat-raised-button mat-dialog-close class="buttonClose" *ngIf="TransSvc.TRANSL">{{TransSvc.TRANSL.close}}</button> -->
  <button mat-button mat-dialog-close class="py-2 px-5 dialog-close-button">Ok</button>
</div>